<template>
  <div class="homeInspectionDetails" >
    <CSBreadcrumb/>
    <div class="result-panel">
      <div class="result-panel-header" >
        <span>
          {{typeList[checkDetailsData.type-1]}}详情
        </span>
        <div class="right">
          <button class="btn btn-primary" @click="downloadPDF">
            下载PDF
          </button>
        </div>
      </div>
      <div class="dialog-form">
         <div class="dialog-form-item">
           <div class="label-header">房间</div>
           <div class="label-content">{{checkDetailsData.name}}</div>
         </div>
         <div class="dialog-form-item">
           <div class="label-header">租客</div>
           <div class="label-content">{{checkDetailsData.lesseeName || '-'}}</div>
         </div>
         <div class="dialog-form-item">
           <div class="label-header">{{checkDetailsData.type==3?'验房时间':'入驻验房时间'}}</div>
           <div class="label-content">{{checkDetailsData.checkinTime}}</div>
         </div>
         <div class="dialog-form-item" v-show="checkDetailsData.type==2">
           <div class="label-header">退租验房时间</div>
           <div class="label-content">{{checkDetailsData.checkoutTime}}</div>
         </div>
         <div class="dialog-form-item">
           <div class="label-header">{{checkDetailsData.type==3?'检查人':'入驻检查人'}}</div>
           <div class="label-content">
              <span v-for="(item,index) in checkDetailsData.checkinNames" :key="index">
                {{item}}{{checkDetailsData.checkinNames.length!==index+1?'、':''}}
              </span>
           </div>
         </div>
         <div class="dialog-form-item" v-show="checkDetailsData.type==2">
           <div class="label-header">退租检查人</div>
           <div class="label-content">
              <span v-for="(item,index) in checkDetailsData.checkoutNames" :key="index">
                {{item}}{{checkDetailsData.checkinNames.length!==index+1?'、':''}}
              </span>
           </div>
         </div>
         <div class="dialog-form-item" >
           <div class="label-header">{{checkDetailsData.type==1?'入驻检查项':checkDetailsData.type==2?'退租检查项':'检查项'}}</div>
           <div class="label-content">{{checkDetailsData.checkItem}}项</div>
         </div>
      </div>
      <div class="table-details">
        <table class="footable table table-stripped toggle-arrow-tiny modal-inner-table">
        <thead>
          <tr >
            <th class="text-center">检查部门</th>
            <th class="text-center">检查项</th>
            <th class="text-center">安装数量</th>
            <th class="text-center">实际数量</th>
            <th class="text-center">完好程度</th>
            <th class="text-center">图片(入)</th>
            <th class="text-center" v-show="checkDetailsData.type==2">退租验房</th>
            <th class="text-center" v-show="checkDetailsData.type==2">图片(退)</th>
          </tr>
        </thead>
        <tbody>
          <tr
              v-for="(item,index) in checkDetailsData.data"
              :key="index"
          >
              <td class="text-center">
                  {{item.dutyName}}
              </td>
              <td class="text-center">
                  {{item.name}}
              </td>
              <td class="text-center">
                  {{item.checkItemNum}}
              </td>
              <td class="text-center">
                  {{item.currentItemNum}}
              </td>
              <td class="text-center">
                  {{item.status || '-'}}
              </td>
              <td class="text-center">
                  <span class="allow-click" @click="lookImg(item.checkinUrl)" v-if="item.checkinUrl">
                      查看
                  </span>
                  <span v-else>-</span>
              </td>
              <td class="text-center" v-show="checkDetailsData.type==2">
                  {{item.checkoutComment?item.checkoutComment:'-'}}
              </td>
              <td class="text-center" v-show="checkDetailsData.type==2">
                  <span class="allow-click" @click="lookImg(item.checkoutUrl)" v-if="item.checkoutUrl">
                     查看
                  </span>
                  <span v-else>-</span>
              </td>
          </tr>
        </tbody>
      </table>
      </div>
      <div class="dialog-form">
         <div class="dialog-form-item">
            <div class="label-header">{{checkDetailsData.type==3?'验房备注':'入驻验房备注'}}</div>
            <div class="label-content">
                <div  style="width:700px">{{checkDetailsData.checkinComment.split('\n')[0] || ''}}</div>
                <div  style="width:700px">{{checkDetailsData.checkinComment.split('\n')[1] || ''}}</div>
                <div  style="width:700px">{{checkDetailsData.checkinComment.split('\n')[2] || ''}}</div>
            </div>
         </div>
         <div class="dialog-form-item" v-show="checkDetailsData.type==2">
           <div class="label-header">退租验房备注</div>
           <div class="label-content">{{checkDetailsData.checkoutComment || '-'}}</div>
         </div>
         <div class="dialog-form-item" >
          <div class="label-header">{{checkDetailsData.type==3?'检查人签字':'入驻检查人签字'}}</div>
          <div class="label-content">
            <img v-for="(item,index) in checkDetailsData.checkinSign"
              :key="index" v-show="item" :src="`${baseUrl}${item}`" alt=""
            >
          </div>
         </div>
         <div class="dialog-form-item" v-show="checkDetailsData.type==2">
          <div class="label-header">退租检查人签字</div>
          <div class="label-content">
            <img v-for="(item,index) in checkDetailsData.checkoutSign"
               :key="index" v-show="item" :src="`${baseUrl}${item}`" alt=""
            >
          </div>
         </div>
         <div class="dialog-form-item">
          <div class="label-header">{{checkDetailsData.type==3?'客户确认签字':'客户入驻确认签字'}}</div>
          <div class="label-content">
            <img :src="`${baseUrl}${checkDetailsData.checkinUserSign}`" alt="" v-if="checkDetailsData.checkinUserSign">
          </div>
         </div>
         <div class="dialog-form-item" v-show="checkDetailsData.type==2">
          <div class="label-header">客户退租确认签字</div>
          <div class="label-content">
            <img :src="`${baseUrl}${checkDetailsData.checkoutUserSign}`" alt="" v-if="checkDetailsData.checkoutUserSign">
          </div>
         </div>

      </div>
    </div>
    <ViewImageModal name="viewImageModal" componentName="ViewImageModal"></ViewImageModal>
  </div>
</template>
<script>
import {
  CheckRecordDetails,
  downLoadPdf
}
from "@/requestUrl"
import CSBreadcrumb from "@/components/common/CSBreadcrumb";
import ViewImageModal from "@/components/ViewImageModal";
export default {
  name:'homeInspectionDetails',
  components:{
    ViewImageModal,
    CSBreadcrumb
  },
  props:{
    id:Number,
  },
  data(){
    return {
      checkDetailsData:{},
      typeList:[
        '入驻验房',
        '退租验房',
        '其它验房'
      ],
      baseUrl:'https://alioss.techops.cn/'
    }
  },
  created () {
    this.queryDetails()
  },
  mounted () {
    // this.queryDetails()
  },
  methods:{
    goBack () {
      this.$router.go(-1)
    },
    queryDetails () {
       this.$fly.get(
         CheckRecordDetails,
         {id:this.id}
       )
       .then((res)=>{
         if(res.code!==0){
           return
         }
         this.checkDetailsData=res.data
       })
    },
    downloadPDF () {
      this.$fly.get(
        downLoadPdf,
        {id: this.id},
        {
          responseType: 'arraybuffer',
          headers: {
            notParse: true,
          }
        })
      .then(res => {
          console.log('res');
          console.log(res);
        const blob = new Blob([res], { type: 'application/pdf'});
        const fileName = `${this.typeList[this.checkDetailsData.type-1]}详情.pdf`;

        if ("download" in document.createElement("a")) {
          const elink = document.createElement("a");
          elink.download = fileName;
          elink.style.display = "none";
          elink.href = URL.createObjectURL(blob);
          document.body.appendChild(elink);
          elink.click();
          URL.revokeObjectURL(elink.href);
          document.body.removeChild(elink);
        } else {
          navigator.msSaveBlob(blob, fileName);
        }
      })
    },
    lookImg (url) {
      // let url="https://alioss.techops.cn/20210415/cb0fe11d-7e35-4c12-8941-84aad27b374d"
      this.$vc.emit(this.$route.path, "viewImageModal", "view", [`https://alioss.techops.cn/${url}`]);
    },
  }
}
</script>
<style scoped lang="stylus">
.result-panel-header
  box-shadow none
  span
    display inline-block
    font-size 24px
    font-weight bold
    font-family PingFangSC-Medium, PingFang SC
    color #000
.right
  float right
  font-size 24px
  button
    line-height 1
.dialog-form
  padding 27px 30px
  font-size 24px
  color #000
  &-item
    display flex
    flex-flow row nowrap
    &:not(:last-of-type)
      margin-bottom 23px
    &-label
      width 150px
      height 33px
      text-align right
      margin-right 40px
      display inline-block
      vertical-align middle
      line-height 33px
      font-weight 400
    & > div
      display inline-block
      vertical-align middle
      input
        &::placeholder
          color #999
        width 140px
        height 40px
        border-radius 4px
        padding 0 5px
        border 1px solid #979797
        vertical-align middle
        padding-left 10px
.dialog-form
  .label-content
    flex 1 1 auto
    font-size 24px
    min-width 350px
    min-height 33px
    text-align left
    vertical-align middle
    & img
      height 44px
      width 110px
  .label-header
    width 200px
    height 33px
    text-align right
    margin-right 40px
    display inline-block
    vertical-align middle
    line-height 33px
    font-weight 400

.table-details
  margin 0 30px
  max-height 340px
  overflow-y auto
  padding 0 2px
  margin 15px 45px 0
 & tbody
  .text-center
    height 30px
 & thead
  .text-center
    height 33px
.cs-style .btn
  font-size 24px
  //width 114px
  height 40px
  margin-top -3px
.result-panel table.table th,
.result-panel table.table td
  padding 10px 0


</style>
